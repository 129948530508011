import { createContext, useContext } from 'react';

export interface IDrawerContext {
  open: boolean;
  contextInitialized: boolean;
}

export const DrawerContext = createContext<IDrawerContext>({ open: false, contextInitialized: false });

export function useDrawerContext() {
  return useContext(DrawerContext);
}
