import _fastDeepEqual from 'react-fast-compare';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

function mapValuesDeep<T>(value: T, callback: (obj: T) => T) {
  if (isPlainObject(value)) {
    return mapValues(value as object, (v) => mapValuesDeep(v, callback));
  }
  return callback(value);
}

// Checks if an object has a null prototype,
// and if it does, clone it to an object without a null prototype
function ensurePrototype<T>(obj: T) {
  if (!obj) return obj;
  if (!(typeof obj === 'object')) return obj;
  if (!('prototype' in obj) || ('prototype' in obj && !obj.prototype)) {
    return { ...obj };
  }
  return obj;
}

/**
 * Wrapper around fastDeepEqual to ensure that
 * objects with null prototypes are replaced by
 * equivalent objects *with* a prototype, since
 * fastDeepEqual doesn't support comparing objects
 * with null prototypes (e.g results from graphqljs)
 *
 * If we don't do this, calling fastDeepEqual to compare
 * props may crash because graphqljs uses objects with null
 * prototypes, which causes fastDeepEqual to throw
 */
export function fastDeepEqual<A, B>(a: A, b: B): boolean {
  // return _fastDeepEqual(mapValuesDeep(a, ensurePrototype), mapValuesDeep(b, ensurePrototype));
  return _fastDeepEqual(a, b);
}
