import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
export const SIZE_MAP = {
  tiny: 12,
  small: 24,
  medium: 32,
  large: 64,
  huge: 128
};

export type TSize = keyof typeof SIZE_MAP | number;

export function getSize(size: TSize) {
  if (typeof size === 'number') return size;
  if (size in SIZE_MAP) return SIZE_MAP[size];
}

export function getFontAwesomeSize(size: TSize): FontAwesomeIconProps['size'] {
  if (getSize(size) < 24) return 'xs';
  if (getSize(size) < 32) return 'sm';
  if (getSize(size) < 64) return 'lg';
  return 'xl';
}
