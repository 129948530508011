import URI from 'urijs';

const TAG_PREFIX_PATTERN = /(\/v\/)([0-9\.a-zA-Z]+)(?=\/)/;
/**
 * Get the bundle tag from the current URL, e.g
 * https://www.zavy.com/v/1.1923.0/
 */
export function getBundleTag() {
  const { pathname } = window.location;
  if (TAG_PREFIX_PATTERN.test(pathname)) {
    return pathname.match(TAG_PREFIX_PATTERN)?.[2];
  }
  return 'current';
}

/**
 * Gets the bundle basename from the current URL, e.g
 * if the URL is https://www.zavy.com/v/1.1923.0/ then
 * the basename is /v/1.1923.0
 */
export function getBundleBasename() {
  if (TAG_PREFIX_PATTERN.test(window.location.pathname)) {
    return window.location.pathname.match(/(\/v\/[0-9\.a-zA-Z]+)\//)?.[0];
  }
  return '/';
}

/**
 * Gets the full URL for the bundle where webpack should load
 * files from, e.g https://www.zavy.com/v/1.1923.0/
 */
export function getBundleUrl() {
  // @ts-ignore
  return URI(getBundleHost()).pathname(`${getBundleTag()}/packs/`).toString();
}

/**
 * Gets the full URL for the bundle where webpack should load
 * files from, e.g https://www.zavy.com/v/1.1923.0/
 */
export function getBundleHost() {
  // @ts-ignore
  return URI(window.assetHost).toString();
}
