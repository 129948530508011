import * as React from 'react';
import { Drawer as AntdDrawer, Button, type ButtonProps, type DrawerProps, Space, Flex } from 'antd';
import { useTheme } from '@zavy360/config/themes';
import { usePlatform } from '@zavy360/hooks/device';
import { useMemo } from '@zavy360/hooks/react';
import { fastDeepEqual } from '@zavy360/utils/equality';
import { DrawerContext } from './Context';

export interface IDrawerProps extends Omit<DrawerProps, 'onClose'> {
  actions?: ButtonProps[];
  actionsPosition?: 'top' | 'bottom';
  onClose(): void;
}

const defaultStyles: DrawerProps['styles'] = {
  body: { background: '#fafafa' }
};

function DrawerComponent(props: IDrawerProps) {
  const { actionsPosition, actions, styles, ...rest } = props;
  const { open } = rest;
  const theme = useTheme();
  const { isMobile } = usePlatform();
  const stylesWithDefaults = useMemo(() => ({ ...defaultStyles, ...styles }), [styles]);
  const contextValue = useMemo(() => ({ contextInitialized: true, open }), [open]);

  return (
    <DrawerContext.Provider value={contextValue}>
      <AntdDrawer
        mask={false}
        push={false}
        width={theme.components.drawer.width}
        closable={!isMobile}
        styles={stylesWithDefaults}
        {...(actionsPosition === 'top'
          ? {
              extra: (
                <Space size='large'>
                  {actions?.map((action, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: We have nothing else here
                    <Button key={`action-${index}`} {...action} />
                  ))}
                </Space>
              )
            }
          : {
              footer: (
                <Flex justify='end'>
                  <Space size='large' align='end'>
                    {actions?.map((action, index) => (
                      // biome-ignore lint/suspicious/noArrayIndexKey: We have nothing else here
                      <Button key={`action-${index}`} {...action} />
                    ))}
                  </Space>
                </Flex>
              )
            })}
        {...rest}
      />
    </DrawerContext.Provider>
  );
}

export const Drawer = React.memo(DrawerComponent, fastDeepEqual);
