// Push log events to a queue, and delay printing them by a few
// hundred milliseconds, so that the logs can be sorted by which
import { takeWhile } from 'lodash';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';

// hook they belong to instead of a random mess of unordered logs
export function createDelayedDebugLogger(delay = 300) {
  const queue = [];
  let timer: ReturnType<typeof setInterval> | null = null;

  function flush() {
    // Sort the logs by what hook they belong to
    // and empty the queue, then print the logs
    const copiedQueue = [];

    // Copy the queue to a new array, and empty the queue
    while (queue.length) {
      copiedQueue.push(queue.shift());
    }
    // Clear the timer until the next message is pushed
    // to the queue
    if (timer && queue.length === 0) {
      clearTimeout(timer);
      timer = null;
    }

    // Reset queue to allow it to fill up again

    // Sort logs and print
    const sortedMessages = orderBy(copiedQueue, (...args) => {
      const firstMessage = takeWhile(args, (arg) => typeof arg === 'string').join();

      // Sort by whatever is in the first square brackets,
      // e.g [Apollo::Hooks::useWhatever], and if no message
      // is provided, then leave it unsorted
      return firstMessage?.toString()?.match(/(?:\[)(.*)(?=\])/gi)?.[1];
    });

    for (const args of sortedMessages) {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }

    if (copiedQueue.length) {
      // eslint-disable-next-line no-console
      console.debug('[Utils::Debug::Logging]: Flushed all messages sorted by origin');
    }
  }

  // Add to the queue
  const debug: typeof console.debug = (...args) => {
    // Start the timer when a message is pushed to the queue
    // if the timer isn't running
    const { millisecond } = DateTime.local().toObject();
    const timePrefix = `[${DateTime.local().toLocaleString(DateTime.TIME_24_WITH_SECONDS)}.${millisecond}]`;
    if (!timer) {
      timer = setInterval(flush, delay);
      // eslint-disable-next-line no-console
      console.debug(
        `${timePrefix}[Utils::Debug::Logging]: Collecting messages for ${delay}ms before printing in order`
      );
    }
    // Push the log message to the queue
    queue.push([timePrefix, ...args]);
  };

  return debug;
}
